<template>
  <b-modal
    :id="`order-claim-modal-${orderId}`"
    :modal-class="'default-modal'"
    header-class="justify-content-between"
    :hide-footer="true"
    @hidden="hidden"
  >

    <template #modal-header="{ close }">
      <div class="text-center">
        <h2 class="highlighted-color1">
          <span>Reivindicar venda</span>
        </h2>
      </div>
      <!--begin::Close-->
      <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close" @click="close()">
          <span class="svg-icon svg-icon-1">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
            </svg>
          </span>
      </div>
      <!--end::Close-->
    </template>
    <AlertWraper
      :content="content"
      :type="content_type"
    />
    <div class="mt-2">
        <form class="default-form" @submit.prevent="">
          <h2 class="text-center mb-5">Essa compra é:</h2>
          <div class="d-flex mb-5 gap-10 justify-content-center">
            <!--begin::Input-->
            <div class="form-check form-check-custom form-check-solid">
              <input class="form-check-input" type="radio" value="FIRST_PURCHASE" name="type" id="first-sale" v-model="form.type"/>
              <label class="form-check-label fs-4" for="first-sale">
                Primeira compra
              </label>
            </div>

            <label class="form-check form-check-custom form-check-solid">
              <input class="form-check-input" type="radio" value="RENEWAL" name="type" id="renovation" v-model="form.type"/>
              <label class="form-check-label fs-4" for="renovation">
                Renovação
              </label>
            </label>
          </div>
          <p class="text-center mb-7"><strong>Importante:</strong> só é considerado renovação se for dentro de 45 dias.</p>
          <h2 class="text-center mb-5">Você é um:</h2>
          <div class="d-flex mb-7 gap-10 justify-content-center">
            <!--begin::Input-->
            <div class="form-check form-check-custom form-check-solid">
              <input class="form-check-input" type="radio" value="AE" name="responsible_profile" id="seller" v-model="form.responsible_profile"/>
              <label class="form-check-label fs-4" for="seller">
                Vendedor (AE)
              </label>
            </div>

            <label class="form-check form-check-custom form-check-solid">
              <input class="form-check-input" type="radio" value="CS" name="responsible_profile" id="custom-success" v-model="form.responsible_profile"/>
              <label class="form-check-label fs-4" for="custom-success">
                Customer Success (CS)
              </label>
            </label>
          </div>
          <div class="d-flex justify-content-around mt-3">
            <Button
              class="btn btn-primary"
              @click="submit"
              :loading="loader"
            >
              Enviar
            </Button>
          </div>
        </form>
    </div>
  </b-modal>
</template>

<script>

import OrderClaimsService from '@/modules/order-claims/services/order-claims-service'
import NotificationService from '@/modules/notifications/services/notifications-service'
import { parseErrorResponseToArray } from '@/utils'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Button from '@/components/common/Button/Button'

export default {
  name: 'OrderClaimModal',
  props: ['orderId'],
  components: {
    AlertWraper,
    Button
  },
  data () {
    return {
      content: null,
      content_type: 'error',
      loader: false,
      form: {
        type: '',
        responsible_profile: '',
        order_id: this.orderId
      }
    }
  },
  methods: {
    /**
     * On modal hidden
     */
    hidden () {
      this.content = null
    },
    /**
     * Submit form
     */
    async submit () {
      this.loader = true

      try {
        await OrderClaimsService.createOrderClaim(this.form)
        this.$bvModal.hide(`order-claim-modal-${this.orderId}`)

        const notification = {
          notification: {
            type: 'success',
            content: 'Reivindicado com sucesso'
          }
        }
        NotificationService.createGlobalSuccessNotification(notification)
        location.reload()
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }

      this.loader = false
    }
  }
}
</script>
